// === trunks-ui component vars ===

// Generic variables
// $hr-background-color: #d1d1d1;
// $hr-height: 1px;

// = helpers
$box-shadow: none;
// $box-shadow-hovered: 1px 3px 10px 2px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1);
$box-padding: 2rem 4rem;

// = components
// $badge-box-shadow: 0 0 0 .2rem #fff;
// $badge-font-size: $size-normal;
// $badge-border-radius: 100%;
// $button-font-weight: $weight-bold;
// $modal-card-title-font-weight: $weight-bold;
// $navbar-dropdown-item-color: $text;
// $page-title-color: $text;
// $tab-highlight-color: $primary;

// = container
// $box-hightlight-color: $primary;
// $section-background-color: $white;

// = forms
// $checkbox-checked-color: $primary;
// $checkbox-checked-border-color: $primary;
// $checkbox-checked-filled-background-color: $primary;
// $checkbox-group-border-color: $border;
// $checkbox-group-divide-color: $border;
// $checkbox-title-filled-background-color: $dark;
// $checkbox-title-filled-color: $light;
// $radio-border-color: $primary;
// $radio-background-color: $primary;
$radio-border-color: $link;
$radio-background-color: $link;

// = lib
// $completer-selected-row-background-color: $primary;
// $completer-selected-row-color: $primary-invert;
// $ngx-datatable-row-detail-background-color: $background;

// ------------------------------------------------------//

// === bulma component vars ===

// = elements
// $box-radius: $radius-large;

// $button-focus-border-color: $link-focus-border;
// $button-focus-box-shadow-color: rgba($link, 0.25);
// $button-active-border-color: $link-active-border;
$button-focus-border-color: invalid-color;
$button-focus-box-shadow-color: invalid-color;
$button-active-border-color: invalid-color;

// $input-hover-color: $grey-darker;
// $input-hover-border-color: $grey-light;
// $input-focus-border-color: $link;
// $input-focus-box-shadow-size: 0 0 0 0.125em;
$input-hover-color: invalid-color;
$input-hover-border-color: invalid-color;
$input-focus-border-color: invalid-color;
$input-focus-box-shadow-color: invalid-color;

// = components
// $navbar-item-hover-color: $link;
// $navbar-item-hover-background-color: $white-bis;
$navbar-dropdown-item-hover-color: $nav-menu;
// $navbar-dropdown-item-hover-background-color: $background;
$navbar-item-hover-color: $nav-menu;
$navbar-item-hover-background-color: $white;

// $modal-z: 40;
// $modal-card-head-background-color: $background;
// $modal-card-head-padding: 20px;
// $modal-card-head-radius: $radius-large;
// $modal-card-foot-radius: $radius-large;
// $modal-card-title-color: $text-strong;
// $modal-card-title-size: 1.5rem;
// $modal-card-body-padding: 20px;
$modal-z: 1020;
$modal-card-head-background-color: $secondary;
$modal-card-head-padding:	1.4rem 2.4rem;
$modal-card-title-color: $color-invert-to-light;
$modal-card-title-size: $size-medium;
$modal-card-body-padding: 3rem;

// ------------------------------------------------------//
