// global style, please prefix class with _g-

body {
  width: $min-supported-screen-width - $vertical-scrollbar-width;
  margin: 0 auto;
}

// modal
.modal-card-title {
  margin-right: 0;
}

.modal-card-head {
  .delete {
    position: absolute;
    right: 12px;
  }
}

// Hero
.hero-body {
  padding-left: 3rem;
  padding-right: 3rem;
}

//Button
.button {
  border-radius: $radius-rounded;
  padding-left: 2em;
  padding-right: 2em;
}

.field.has-addons .control:last-child .button {
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
  padding-left: 0.75em;
  padding-right: 0.75em;
}

//Input
.input,
.textarea {
  box-shadow: none;
}

.is-required label:after {
  color: $blue-light;
}

.label {
  font-weight: normal;
}

//Select
.select:not(.is-multiple):not(.is-loading)::after {
  border-color: $grey;
}

._g-content-section {
  padding: 3rem 5rem;
  background-color: $light;

  hr {
    background-color: $grey-lighter;
  }
}

.dropdown-item {
  font-size: 1em;
}

._g-root-exam {
  background: black;
  overflow: hidden;

  body {
    // not need to reserve space for scrollbar because they are inner overflow and iframe
    width: $min-supported-screen-width;
  }
}

._g-link {
  color: $link;
  text-decoration: underline;
}

._g-exam-container {
  display: flex;
  flex-direction: column;
  background: $white;
  height: 100%;
}

._g-exam-layout {
  flex: 1;
  display: flex;
  height: calc(100vh - 58px);
  max-height: calc(100vh - 58px);
  background-color: $primary;
}

._g-question-side {
  flex-shrink: 0;
  width: 388px;
  height: 100%;
  padding: 10px;
  margin-right: 3px;
  overflow-y: auto;
  background-color: white;

  ._g-exam-item-component {
    padding: 0 10px;
  }
}

._g-html-side {
  flex-shrink: 0;
  width: 633px;
  height: 100%;
  background-color: white;
}

._g-input-replacement-box,
._g-textarea-replacement-box,
._g-input-math-replacement-box {
  border: 1px solid $border;
  border-radius: $radius;
}

._g-input-replacement-box {
  padding: 0.5rem 0.9rem;
  min-height: 36px;
}

._g-textarea-replacement-box {
  padding: 1rem;
  min-height: 120px;
}

._g-input-math-replacement-box {
  padding: 0.5rem 0.9rem;
  width: 100%;
  min-height: 4em;
  line-height: 1;
}

._g-input-math {
  height: 4em;
  line-height: 1;
}

._g-cmd-math {
  border: 1px solid $border;
  border-bottom: 0;
  margin-bottom: 0 !important;
  padding: 0.5rem;

  .button {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0.05em;
    border-radius: 4px;
    width: 40px;

    &:hover {
      color: $text;
    }
  }

  .button-space {
    width: 40px;
    height: 36px;
    margin-right: 0.5rem;
  }
}

// Datatable
.ngx-datatable.material {
  box-shadow: none;
  border: 1px solid $border;

  .datatable-footer {
    .page-count {
      flex: initial;
    }

    .datatable-pager {
      flex: 1;
    }
  }
}

.datatable-body-row.datatable-row-odd {
  background-color: $bg;
}

.datatable-body-cell-label {
  width: 100%;
}


// mathquill
.mq-editable-field.mq-focused,
.mq-math-mode .mq-editable-field.mq-focused {
  box-shadow: none;
  border-color: $border;
  border-radius: $radius;
}

.mq-math-mode .mq-supsub.mq-sup-only {
  vertical-align: .75em;
}

.mq-editable-field .mq-latex-command-input,
.mq-math-mode var,
.mq-math-mode .mq-text-mode,
.mq-math-mode .mq-nonSymbola {
  font-family: $family-sans-serif;
}

.mq-math-mode,
.mq-math-mode .mq-operator-name,
.mq-math-mode .mq-editable-field {
  font-family: $family-sans-serif;
}

.mq-math-mode .mq-sqrt-prefix {
  font-family: $family-math;
}