@import '~trunks-ui/utilities/functions';

// $variable-columns: false;

// Base font size
$body-size: 10px;
// $body-size-mobile: $body-size;

// The section horizontal gap for desktop
$section-gap: 22px;

// The section horizontal gap for mobile
// $section-gap-mobile: $body-size-mobile * 1.5;

// The container horizontal gap for desktop, which acts as the offset for breakpoints
// In another word, this is minimum overall gap for desktop. It will be increase when window width > container's width + $gap
// Should be >= section gap
// $gap: $section-gap + 40;
$gap: $section-gap;

// The container horizontal gap for mobile is none by design

// The gap between two columns
$column-gutter: 2.2rem;
// $column-gutter-mobile: $column-gutter !default;

// Responsive Breakpoint
// mobile up to 767px;
// $tablet: 768px; // tablet size by chrome's device toolbar    // for 768 = ipad portrait
// $desktop: 1024px; // desktop size by chrome's device toolbar // for 1024 = ipad landscape = ipad pro portrait
// $widescreen: 1280px;  // widescreen size by bulma default    // for 1280, 1366 = ipad pro landscape, 1440
// $fullhd: 1536px; // fullhd size by diff of each screen = 256 // for 1600, 1920, 2560

// For Non-Responsive, Uncomment these vars.
$tablet: 1px;
$desktop: 2px;
$widescreen-enabled: false;
$fullhd-enabled: false;
$min-supported-screen-width: 1024px; // change to fit your requirement
// And then @import "~trunks-ui/non-responsive"; next to "~trunks-ui/trunks";

// Font Family
// $family-sans-serif: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
//  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-sans-serif: 'Kanit', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$family-math: Symbola, 'Kanit', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

// Font size
$size-small: 1.2rem;
$size-normal: 1.6rem;
$size-medium: 1.8rem;
$size-large: 2.4rem;
$size-xlarge: 2.8rem;
$size-xxlarge: 3.4rem;

// Weight
$weight-normal: 400;
$weight-bold: 700;

// Miscellaneous
// $easing: ease-out;
// $radius-small: 2px;
// $radius: 4px;
// $radius-large: 6px;
// $radius-rounded: 290486px;
// $speed: 86ms;

// -- Custom vars defind here --


// Shade
$black-bis: hsl(0, 0%, 7%);
$black-ter: hsl(0, 0%, 14%);
$grey-darker: hsl(0, 0%, 21%);
$grey-dark: hsl(0, 0%, 29%); // #454545
$grey: hsl(0, 0%, 48%); // set #7B7B7B
$grey-light: hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 88%); // set #E0E0E0
$white-ter: hsl(0, 0%, 95%); // set
$white-bis: hsl(0, 0%, 98%);

// Colors
$black: hsl(0, 0%, 4%);
$white: hsl(0, 0%, 100%);
$yellow: #E4BB3E;
$orange: #F29E79;
$green: #4BC18F;
$green-light: #C8DA61;
$turquoise: #10789F;
$blue: #2F74C1;
$blue-fade: #5EA3D7;
$blue-light: #5193FB;
$red: #CF4538;

// Meaning Colors
$primary: $green;
$secondary: $blue;
$info: $blue-light;
$success: $green;
$warning: $orange;
$danger: $red;
$light: $white-ter;
$dark: $grey-darker;

// Custom color
$nav-menu: $secondary;

$stepper-prev: $blue;
$stepper-active: $blue;
$stepper-next: $grey-lighter;

$table-header: $blue-fade;
$table-subheader: $grey-lighter;
$table-border: $grey-light;

$exam-part-info: $blue-fade;

// Custom color with class
$back: $grey;
$bg: $white-ter;
$correct-result: $success;
$wrong-result: $danger;
$no-result: $grey;
$correct-answer-input: $success;
$result-user-input: $grey;

// Custom color class
$custom-colors: (
  "secondary": ($secondary),
  // will remove
  "back": ($back),
  "bg": ($bg),
  "correct-result": ($correct-result),
  "wrong-result": ($wrong-result),
  "no-result": ($no-result),
  "correct-answer-input": ($correct-answer-input),
  "result-user-input": ($result-user-input),
);
$custom-shades: null;

// Text color result for findColorInvert()
$color-invert-to-dark: $grey-darker;
$color-invert-to-light: $white;

$primary-invert: findColorInvert($primary); // will remove

// General colors
// $body-background-color: $white;
// $background: $white-ter; 
// $border: $grey-lighter;
// $border-hover: $grey-lighter;

// Text colors
$text: $grey-darker;
// $text-invert: $white;
// $text-light: $grey;
$text-strong: $black;
// $text-disabled: $grey-light;

// Link colors
// $link: $primary !default;
$link: $blue-light;
// $link-invert: $primary-invert !default;
// $link-visited: $link !default;

$link-hover: $blue-light;
// $link-hover-border: invalid-color;

// $link-focus: invalid-color;
// $link-focus-border: invalid-color;

// $link-active: invalid-color;
// $link-active-border: invalid-color;

@import '~trunks-ui/utilities/variables';
@import '~trunks-ui/utilities/mixins';