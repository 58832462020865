@font-face {
	font-family: 'Kanit';
	src: url('../font/Kanit-Medium.ttf');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Kanit';
	src: url('../font/Kanit-Regular.ttf');
	font-weight: normal;
	font-style: normal;
}


/*  */

@font-face {
	font-family: 'THSarabunNew';
	src: url('../font/THSarabunNew.ttf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'THSarabunNew';
	src: url('../font/THSarabunNew-Bold.ttf');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'THSarabunNew';
	src: url('../font/THSarabunNew-Italic.ttf');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'THSarabunNew';
	src: url('../font/THSarabunNew-BoldItalic.ttf');
	font-weight: bold;
	font-style: italic;
}
