$icomoon-font-path: "assets/font" !default;

@font-face {
  font-family: 'icomoon';
  src: url('#{$icomoon-font-path}/icomoon.woff2?1gcyzz') format('woff2'),
  url('#{$icomoon-font-path}/icomoon.ttf?1gcyzz') format('truetype'),
  url('#{$icomoon-font-path}/icomoon.woff?1gcyzz') format('woff'),
  url('#{$icomoon-font-path}/icomoon.svg?1gcyzz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.ic {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-queue:before {
  content: "\e900";
}

.ic-time-queue:before {
  content: "\e901";
}

.ic-user-circle:before {
  content: "\e902";
}

.ic-warning:before {
  content: "\e903";
}

.ic-school:before {
  content: "\e904";
}

.ic-calendar:before {
  content: "\e905";
}

.ic-clock-o:before {
  content: "\e942";
}

.ic-copy:before {
  content: "\e994";
}

.ic-exam-dragdrop:before {
  content: "\e978";
}

.ic-bell:before {
  content: "\e986";
}

.ic-calculator:before {
  content: "\e979";
}

.ic-eye:before {
  content: "\e97a";
}

.ic-eye-slash:before {
  content: "\e97b";
}

.ic-add-circle:before {
  content: "\e97c";
}

.ic-button_back:before {
  content: "\e97d";
}

.ic-button_next:before {
  content: "\e97e";
}

.ic-button_question:before {
  content: "\e97f";
}

.ic-check:before {
  content: "\e980";
}

.ic-check-circle:before {
  content: "\e981";
}

.ic-close:before {
  content: "\e98d";
}

.ic-download:before {
  content: "\e98e";
}

.ic-exam-multiple-answer:before {
  content: "\e98f";
}

.ic-exam-multiple-choice:before {
  content: "\e990";
}

.ic-exam-multiple-choice-reason:before {
  content: "\e991";
}

.ic-exam-short-answer:before {
  content: "\e992";
}

.ic-exam-subjective:before {
  content: "\e993";
}

.ic-exam-table-multiple-question:before {
  content: "\e982";
}

.ic-exam-tablemultiple-answer:before {
  content: "\e983";
}

.ic-home:before {
  content: "\e984";
}

.ic-list-alt:before {
  content: "\e985";
}

.ic-matching:before {
  content: "\e987";
}

.ic-pencil:before {
  content: "\e988";
}

.ic-plus:before {
  content: "\e989";
}

.ic-question:before {
  content: "\e98a";
}

.ic-re-order:before {
  content: "\e9bd";
}

.ic-trash:before {
  content: "\e98b";
}

.ic-user:before {
  content: "\e98c";
}