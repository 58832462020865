// fix conflict with icon-em class
.button .icon:first-child:not(:last-child) {
  margin-left: auto;
  margin-right: 0.5em;
}

// fix IE arrow
.has-arrow-right::after,
.has-arrow-left::after,
.has-arrow-up::after,
.has-arrow-down::after {
  display: block;
}

// better sort arrow
.sortable {
  .sort-btn {
    &:after {
      font-family: data-table;
      content: 'c';
    }

    &.datatable-icon-down:after,
    &.datatable-icon-up:after {
      content: '';
    }
  }
}
// hide ngx-datatable's sort-btn when use grid component
.grid-sort-btn + .sort-btn {
  display: none !important;
}

// radio
.radio.has-no-text input[type=radio] + span {
  padding-left: 1.25em;
}

.select.is-multiple-select {
  height: auto;
}


// add validation
.is-show-only-form-submitted {
  display: none;
}
form.submitted .is-show-only-form-submitted {
  display: block;
}

// helper
.is-fullheight {
  height: 100%;
}
.is-vertical-baseline {
  vertical-align: baseline;
}


// modal

.modal {
  &.modal-full {
    .modal-content,
    .modal-card {
      @include tablet {
        width: 100%;
      }

      @include desktop {
        width: 100%;
      }
    }
  }

  &.modal-auto {
    .modal-content,
    .modal-card {
      @include tablet {
        width: auto;
      }

      @include desktop {
        width: auto;
      }
    }
  }

  &.modal-fullheight {
    .modal-content,
    .modal-card {
      @include tablet {
        height: 100%;
      }

      @include desktop {
        height: 100%;
      }
    }
  }
}